<template>
  <div
    class="w-full flex items-center justify-center lg:gap-x-6 h-16 md:h-10 mt-4 mx-auto"
    :style="`background-color: ${blok?.backgroundColor.color || '#5F0F40'}; color: ${
      blok?.textColor.color || '#ffffff'
    }`"
  >
    <div v-if="isMobileOrTablet" class="flex items-center">
      <component :is="blok.mobile[0].component" :blok="blok.mobile[0]" />
    </div>
    <div v-if="!isMobileOrTablet" class="flex items-center overflow-hidden">
      <component
        :is="blok.left[0].component"
        :blok="blok.left[0]"
        :svgColor="blok?.textColor.color"
      />
    </div>
    <div v-if="!isMobileOrTablet" class="flex items-center overflow-hidden">
      <component
        :is="blok.center[0].component"
        :blok="blok.center[0]"
        :svgColor="blok?.textColor.color"
      />
    </div>
    <div v-if="!isMobileOrTablet" class="flex items-center">
      <component :is="blok.right[0].component" :blok="blok.right[0]" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
})

const { isMobileOrTablet } = useDevice()
</script>
